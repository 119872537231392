import '../styles/index.scss';
import React, { Component } from 'react';
import SEO from '../components/seo';
import Hero from '../components/hero';
import AboutComplex from '../components/about';
import Infrastructure from '../components/infrastructure';
import Advantages from '../components/advantages';
import Footer from '../components/footer';
import { graphql } from 'gatsby';

const subheader = "Жилой комплекс «Dresden» создан для людей, которые любят и ценят своё время и комфорт. Наша команда разработала концепцию идеального места для жизни и отдыха в лучшей локации города Каменское! В непосредственной близости находятся новые инфраструктурные объекты (Эпицентр, в скором времени будет построен современный торгово-развлекательный комплекс), что обеспечит максимальное удобство и экономию времени, которое можно отлично провести с семьей на территории комплекса Dresden, наслаждаясь роскошным бассейном и авторским ландшафтным дизайном! Вы имеете доступ на всю территорию комплекса, при этом ничто не нарушает Вашего спокойствия.";

class AboutPage extends Component {
  componentDidMount() {
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    if (bodyOverflow === 'hidden') document.documentElement.style.overflow = 'visible';
  }

  render() {
    return (
      <div className="about-page page">
        <SEO title="О Комплексе" />

        <Hero
          heading="о комплексе"
          subheader={ subheader }
          bgImg={this.props.data.bg.childImageSharp.fluid}
          bigBtnText="выбрать таунхаусы"
          bigBtnLink="/townhouses"
          bigBtnText2="выбрать квартиры"
          bigBtnLink2="/flats"
        />
        <AboutComplex plan={this.props.data.plan.childImageSharp.fluid} title="Планировка" />
        <Infrastructure />
        <Advantages />
        <Footer />
      </div>
    )
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    bg: file(relativePath: { eq: "bg/about_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    plan: file(relativePath: { eq: "img/plan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
