import React, { useState } from 'react';
import Header from '../header';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import WatchVideoBtn from './watchVideo';
import Container from '../container';

const handleNavClick = () =>
  window.scrollTo({
    left: 0,
    top: 100000,
    behavior: 'smooth'
  });

const Hero = ({
    heading,
    subheader,
    bgImg,
    bgEl,
    showVideoBtn,
    bigBtnText,
    bigBtnLink,
    bigBtnText2,
    bigBtnLink2,
  }) => {
  const [ menuBgVisible, setMenuVisible ] = useState(false);

  const toggleBg = () => {
    setMenuVisible(!menuBgVisible);
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    document.documentElement.style.overflow = bodyOverflow === 'hidden'
      ? 'visible'
      : 'hidden';
  }

  return (
    <section className="hero full-screen section-bg-dark">
      <div className="bg">
        <Img fluid={bgImg} alt="background" />
        { bgEl }
      </div>
      <div className={`menu-bg ${menuBgVisible ? 'active' : ''}`} />

      <Header toggleBg={toggleBg} active={menuBgVisible} />

      <Container>
        <div className="content">
          <div className="titles">
            <h1>{ heading }</h1>

            { subheader && (<p>{ subheader }</p>) }

            <div className="buttons">
              <Link to={ bigBtnLink }>
                <button className="btn-big">
                  { bigBtnText }
                </button>
              </Link>

              {
                bigBtnText2 && bigBtnLink2 && (
                  <Link to={ bigBtnLink2 } className="extra-big-btn">
                    <button className="btn-big">
                      { bigBtnText2 }
                    </button>
                  </Link>
                )
              }

              { showVideoBtn && <WatchVideoBtn /> }
            </div>
          </div>

          <div className="learn-more" onClick={handleNavClick}>
            <img src="/icons/details_icon.svg" alt="learn more" />
            <span>Свяжитесь с нами</span>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Hero;
