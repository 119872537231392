import React from 'react';
import { PillarVertical } from '../pillars';
import { LineBottom } from '../lines';
import ListIcon from './listIcon';
import Layout from '../layout';
import Container from '../container';

const Infrastructure = () => {
  return (
    <Layout className="infrastructure section-bg-dark">
      <span className="DRESDEN">DRESDEN</span>

      <Container>
        <div className="content">
          <LineBottom />

          <div className="left">
            <PillarVertical style={{ top: -110, right: 0 }} />

            <div className="img-container" />
          </div>

          <div className="right">
            <h2>ИНФРАСТРУКТУРА</h2>

            <div className="list">
              <div className="column">
                <div>
                  <ListIcon number={1} />
                  <span>Роскошные таунхаусы от 130 м<sup>2</sup> с благоустроенной зоной парковки для 2-х автомобилей и собственным двором</span>
                </div>
                <div>
                  <ListIcon number={3} />
                  <span>Уютные квартиры от 33 м<sup>2</sup> с лучшими планировочными решениями</span>
                </div>
                <div>
                  <ListIcon number={5} />
                  <span>Дополнительная парковка открытого типа</span>
                </div>
                <div>
                  <ListIcon number={7} />
                  <span>Индивидуальный бассейн с оборудованной зоной отдыха</span>
                </div>
                <div>
                  <ListIcon number={9} />
                  <span>Авторский ландшафтный дизайн</span>
                </div>
              </div>
              <div className="column">
                <div>
                  <ListIcon number={2} />
                  <span>Круглосуточный пункт охраны для вашей абсолютной безопасности</span>
                </div>
                <div>
                  <ListIcon number={4} />
                  <span>Круглосуточное видеонаблюдение по всей территории комплекса</span>
                </div>
                <div>
                  <ListIcon number={6} />
                  <span>Сервисная служба 24/7</span>
                </div>
                <div>
                  <ListIcon number={8} />
                  <span>Спортивный клуб и площади коммерческого назначения для максимального комфорта жильцов</span>
                </div>
                <div>
                  <ListIcon number={10} />
                  <span>Детская площадка с безопасным мягким покрытием</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Infrastructure;
