import React from 'react';

const ListIcon = ({ number }) => (
  <div
    className="list-icon"
    style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <img
      style={{
        width: 48,
        height: 40,
        maxWidth: 'none'
      }}
      src="/icons/house-list-icon.svg"
      alt=""
    />

    <span style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -30%)',
      fontFamily: "Oswald, sans-serif",
      color: '#242424'
    }}>
      { number }
    </span>
  </div>
);

export default ListIcon;