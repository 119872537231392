import React from "react";
// import { PillarVertical } from '../pillars';
import { LineBottom } from '../lines';
import { Link } from 'gatsby';
import Layout from '../layout';
import Container from '../container';

const AboutComplex = ({ title }) => (
  <Layout id="about" className="about full-screen section-bg-light">
    <Container>
      <div className="wrapper-outer">

        <div className="wrapper-inner">
          {/* <h2 className="PLAN">PLAN</h2> */}

          <div className="content">
            <LineBottom style={{ bottom: 80 }} />

            {/* <div className="left-side"> */}
            {/*   <PillarVertical style={{ top: -110, left: "100%" }} /> */}
            {/*  */}
            {/*   <div className="title"> */}
            {/*     <h2>{ title || 'О КОМПЛЕКСЕ' }</h2> */}
            {/*   </div> */}
            {/*  */}
            {/*   <div className="plan-wrapper mobile"> */}
            {/*     <img src="/gallery/7.jpg" alt="plan" /> */}
            {/*   </div> */}
            {/*  */}
            {/*   <table className="advantages"> */}
            {/*       <tr> */}
            {/*         <td className="number">1</td> */}
            {/*     <tbody> */}
            {/*         <td className="label">Улица таунхаусов</td> */}
            {/*         <td className="number">2</td> */}
            {/*         <td className="label">Квартиры</td> */}
            {/*       </tr> */}
            {/*  */}
            {/*       <tr> */}
            {/*         <td className="number">3</td> */}
            {/*         <td className="label">Открытый паркинг</td> */}
            {/*         <td className="number">4</td> */}
            {/*         <td className="label">Прогулочная аллея</td> */}
            {/*       </tr> */}
            {/*     </tbody> */}
            {/*   </table> */}
            {/*  */}
            {/*   <div className="appeal"> */}
            {/*     <p> */}
            {/*       В строительстве ЖК Dresden мы используем материалы только премиального качества, индивидуальные архитектурные решения и новейшие инженерные разработки! */}
            {/*     </p> */}
            {/*  */}
            {/*     { */}
            {/*       !title && ( */}
            {/*         <Link to="/about"> */}
            {/*           <button className="learn-more">ПОДРОБНЕЕ О КОМПЛЕКСЕ</button> */}
            {/*         </Link>) */}
            {/*     } */}
            {/*   </div> */}
            {/* </div> */}

            <div className="right-side">
              <div className="title">
                <h2>ГЕНЕРАЛЬНЫЙ ПЛАН</h2>
                <LineBottom />
              </div>

              <div className="plan-wrapper">
                <img src="/gallery/7.jpg" alt="plan" />
              </div>

              {/* <span>Генеральный план</span> */}

              {
                !title && (
                  <Link to="/about">
                    <button className="learn-more">ПОДРОБНЕЕ О КОМПЛЕКСЕ</button>
                  </Link>)
              }
            </div>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)

export default AboutComplex
