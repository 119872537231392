import React from 'react';
import { PillarVertical } from '../pillars';
import { LineBottom } from '../lines';
import Layout from '../layout';
import Container from '../container';

const Advantages = () => {
  return (
    <Layout className="advantages-section section-bg-light">
      <Container>
        <div className="content">
          <span className="BENEFITS">BENEFITS</span>

          <LineBottom />

          <div className="left">
            <PillarVertical style={{ top: -110, right: 0 }} />

            <h2>ПРЕИМУЩЕСТВА</h2>

            <ul>
              <li>Капитальные монолитные фундаменты</li>
              <li>Заводские железобетонные конструкции от завода «Ковальской»</li>
              <li>100% натуральный, экологически чистый керамический полнотелый кирпич (толщина кирпичной кладки 400мм +утеплитель 100мм)</li>
              <li>Утепление дома выполнено по энергосберегающей технологии класса А++ (толщина утепления 100мм, толщина стен с утеплением 500мм)</li>
              <li>Черепичная кровля от лучшего финского производителя подчеркнёт элитность Вашего дома</li>
              <li>Заводские бронированные входные двери</li>
              <li>Элитные энергосберегающие 5-и камерные окна с двойным стеклопакетом (3 стекла) Rehau</li>
              <li>Индивидуальный электрический котёл Bosch</li>
              <li>Дома оснащены всеми необходимыми коммуникациями (водоснабжение, электрика, канализация, скоростной интернет)</li>
            </ul>
          </div>

          <div className="right">
            <div className="wrapper">
              <div style={{ backgroundImage: "url('/gallery/3.jpg')" }} />
              <div style={{ backgroundImage: "url('/gallery/17.jpg')" }} />
              <div style={{ backgroundImage: "url('/gallery/1.jpg')" }} />
              <div style={{ backgroundImage: "url('/gallery/20.jpg')" }} />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Advantages;
