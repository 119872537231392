import React from 'react';

const WatchVideoBtn = () => (
  <div className="watch-video-wrapper">
    <a href="https://www.youtube.com/channel/UCtizSQAQuwn39CvamWbzBTg">
      <span>Cмотреть&nbsp;видео</span>
      <img src="/icons/play_video_icon.svg" alt="play video" />
    </a>
  </div>
);

export default WatchVideoBtn;
